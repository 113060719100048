import { Config } from "config/config.type"
/** @firescoutMockVar Config.config */
const config:Config = {
  "env": "prod",
  "location": "lusini_fr-be",
  "shopName": "lusini",
  "locale": "fr-be",
  "rootCategoryId": "EMG",
  "availableCreditCards": [
    "MASTERCARD",
    "VISA"
  ],
  "links": {
    "contact": "/aide-et-services/contactez-nous/"
  },
  "i18n": {
    "currency_ISO": "EUR",
    "locale": "fr-BE",
    "country": "BE"
  },
  "slugifyLocale": "fr",
  "curHrefLang": "fr-be",
  "hrefLang": [
    {
      "configFolder": "lusini",
      "locale": "fr-be",
      "hrefLang": "fr-be"
    },
    {
      "configFolder": "lusini",
      "locale": "fr-ch",
      "hrefLang": "fr-ch"
    },
    {
      "configFolder": "lusini",
      "locale": "fr-fr",
      "hrefLang": "fr"
    }
  ],
  "baseUrl": "https://www.lusini.com",
  "modules": {
    "onetrust": {
      "dataDomainScript": "bda0c016-94be-4fd9-943a-b9c09791a327"
    },
    "buzdev": {
      "errorUrl": "https://buz.lusini.com/webhook/com.lusini.commerce-platform/error/1-0-1"
    },
    "cloudinary": {
      "endpoint": "https://res.cloudinary.com/lusini/",
      "transformations": {
        "freisteller_l": "w_1500,h_1500,q_80,c_pad,f_auto",
        "freisteller_l_2": "w_1500,h_1500,q_80,c_pad,f_auto",
        "milieu_l": "w_1500,h_1500,g_auto,q_80,c_fill,f_auto",
        "milieu_l_2": "w_1500,h_1500,g_auto,q_80,c_fill,f_auto",
        "freisteller_m": "w_500,h_500,q_70,c_pad,f_auto",
        "freisteller_m_2": "w_500,h_500,q_70,c_pad,f_auto",
        "milieu_m": "ws_500,h_500,g_auto,q_70,c_fill,f_auto",
        "milieu_m_2": "ws_500,h_500,g_auto,q_70,c_fill,f_auto",
        "freisteller_s": "w_300,h_300,q_70,c_pad,f_auto",
        "freisteller_s_2": "w_600,h_600,q_70,c_pad,f_auto",
        "milieu_s": "w_300,h_300,g_auto,q_70,c_fill,f_auto",
        "milieu_s_2": "w_600,h_600,g_auto,q_70,c_fill,f_auto",
        "freisteller_xs": "w_50,h_50,q_50,c_pad,fl_lossy,f_auto",
        "freisteller_xs_2": "w_100,h_100,q_50,c_pad,fl_lossy,f_auto",
        "milieu_xs": "w_50,h_50,g_auto,q_50,c_fill,fl_lossy,f_auto",
        "milieu_xs_2": "w_100,h_100,g_auto,q_50,c_fill,fl_lossy,f_auto"
      },
      "fallback_product_picture": "https://res.cloudinary.com/lusini/image/upload/v1643182695/application/products/lusini-online-shop-platzhalter-produktbild-in-kuerze-fr.svg"
    },
    "shopware": {
      "salutations": [
        "mr",
        "ms",
        "not_specified"
      ],
      "initialSaluationId": "6d0b33d96b5b400cbabc648c0a4c3f60",
      "accessKey": {
        "b2b": "SWSCVNA94OJTY4APW2OH52I57M"
      },
      "storefrontUrl": {
        "b2b": "https://www.lusini.com/fr-be/b2b"
      },
      "apiUrl": "https://shop-api.lusini.com/store-api",
      "languageId": "b13defd72b2c4caabe151ec86b57f75c"
    },
    "algolia": {
      "apiKey": "bf4bf9193d5f34cd369647376521dbc8",
      "applicationId": "WAVYBEW6OQ"
    },
    "contentful": {
      "space": "aza65graowyr",
      "environment": "master",
      "accessToken": "OExq-bY8Ujs7M06rQEL1drC2kfafk-qmEzA8DZO0CCE",
      "previewToken": "QP7JxRhc0mYWGrX3I8vj9WdBO_eF7n9r0xnmcX11HAw"
    },
    "dyHybrid": {
      "apiKey": "a72829a6281955d5f8febc70eeb0a8de8a9f4841db410f4012d1fb4bf2dc5ffa",
      "campaignID": "9879544"
    },
    "trustShop": {
      "placeholderImg": "https://res.cloudinary.com/lusini/image/upload/c_scale,w_100/v1670412947/application/icons/trusted-shops-guarantee.svg",
      "id": "X9FA9C2C1101BDDDDA83547B19DB1A4BD",
      "url": "https://widgets.trustedshops.com/js/",
      "urlPostfix": ".js"
    },
    "zenloop": {
      "id": "TVdFNFl6WmxOVGt0T1RNM09TMDBOalJoTFdFMU9EZ3RPV1U0WWpOa04yTTBNR0pr"
    },
    "hotjar": {
      "id": 3549008,
      "version": 6
    }
  },
  "index": {
    "products": "prod_lusini_fr_BE_products",
    "productsPriceAsc": "prod_lusini_fr_BE_products_priceAsc",
    "categories": "prod_lusini_fr_BE_categories",
    "series": "prod_lusini_fr_BE_series",
    "productsSuggestion": "prod_lusini_fr_BE_products_query_suggestions",
    "productsLatest": "prod_lusini_fr_BE_products_noveltiesAsc",
    "productReviews": "prod_lusini_product_reviews"
  },
  "deliveryDate": {
    "shippingTooLong": 200
  },
  "colors": {
    "Blanc": "white",
    "Noir": "black",
    "Gris": "grey",
    "Argenté": "silver",
    "Brun": "brown",
    "Bleu": "blue",
    "Multicolore": "multicolor",
    "Rouge": "red",
    "beige": "beige",
    "Vert": "green",
    "Transparent": "transparent",
    "Jaune": "yellow",
    "Doré": "gold",
    "Orange": "orange",
    "Rose/Lilas": "lilac"
  },
  "features": {
    "devMode": false,
    "trustShop": true,
    "zenloop": true,
    "b2c": false,
    "acceptanceOfTermsRequiredForOrderingB2C": true,
    "acceptanceOfTermsRequiredForOrderingB2B": true,
    "userLikeWidgetUrl": "",
    "notificationBar": true,
    "customizableUrl": "https://www.lusini.com/fr-be/aide-et-services/nos-services/personnalisation/"
  },
  "langShop": {
    "current": {
      "language": "Langue",
      "locale": "fr-BE",
      "label": "Français"
    },
    "alternate": [
      {
        "label": "Français",
        "url": "https://www.lusini.com/fr-be/",
        "active": true,
        "locale": "fr-BE"
      },
      {
        "label": "Nederlands",
        "url": "https://www.lusini.com/nl-be/",
        "active": false,
        "locale": "nl-BE"
      }
    ]
  },
  "abTests": {},
  "showTelephoneContactOnFailedCheckoutThreshold": 0
}
export default config